import React from "react";
import {Col, Row} from "react-bootstrap";
import {DiGit, DiJava, DiJavascript1, DiLaravel, DiMongodb, DiMysql, DiNodejs, DiPhp, DiPython,} from "react-icons/di";
import {FaFlask} from "react-icons/fa";
import {SiC, SiGnubash, SiSpigotmc} from "react-icons/si";

function Techstack() {
    return (
        <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
            <Col xs={4} md={2} className="tech-icons">
                <SiC/>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <FaFlask/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiGit/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiGnubash/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiJava/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiJavascript1/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiLaravel/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiMongodb/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiMysql/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiNodejs/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiPhp/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <DiPython/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiSpigotmc/>
            </Col>
        </Row>
    );
}

export default Techstack;
