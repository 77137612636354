import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
function Home2() {
  return (
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
                <span className="purple">Présentation</span>
              </h1>
              <p className="home-about-body">
                Depuis que je suis jeune,
                j'ai une passion pour la programmation,
                surtout depuis que j'ai découvert qu'il était possible de créer et de modifier des éléments dans le jeu Minecraft en développant des plugins et/ou des mods,
                un jeu auquel j'ai beaucoup joué.
                <br />
                <br />Je maîtrise des langages classiques comme
                <b className="purple"> Java, PHP </b>
                et
                <b className="purple"> Python. </b>
                <br />
                <br />
                À l'avenir, j'aimerais devenir
                <b className="purple"> Architecte Logiciel. </b>
                J'ai pu découvrir cet aspect grâce à mon expérience dans l'association <b className="purple"> Skytale. </b>.
                <br/>
                <br />
                Chaque fois que possible, j'applique également ma passion pour le développement de plugins Minecraft
                avec <b className="purple"> Java</b> et
                l'API <b className="purple"> Spigot/Paper </b>.
              </p>
            </Col>
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>Rejoingez-moi sur</h1>
              <p>
                N'hésitez pas à <span className="purple">me contacter</span>
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                      href="https://x.com/_C0rin_"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <FaSquareXTwitter/>
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://github.com/Corin-alt"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub/>
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://www.linkedin.com/in/corentindpt51/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn/>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Home2;
