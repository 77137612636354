import React from "react";
import {Col, Row} from "react-bootstrap";
import {
    SiDocker,
    SiGitlab,
    SiJetbrains,
    SiLaragon,
    SiLinux,
    SiMicrosoftoffice,
    SiVisualstudiocode,
    SiWindows
} from "react-icons/si";

function Toolstack() {
    return (
        <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
            <Col xs={4} md={2} className="tech-icons">
                <SiDocker/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiGitlab/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiJetbrains/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiLaragon/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiLinux/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiMicrosoftoffice/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiVisualstudiocode/>
            </Col>

            <Col xs={4} md={2} className="tech-icons">
                <SiWindows/>
            </Col>
        </Row>
    );
}

export default Toolstack;
