import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
      <Card className="quote-card-view">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ textAlign: "justify" }}>
              Bonjour à tous, je suis <span className="purple">Corentin Dupont</span>,
              étudiant de 24 ans en deuxième année de Master en Informatique à l'Université de Reims.
              <br />
              Je suis actuellement apprenti <span className="purple">Développeur d'Applications et de Sécurité</span> chez <a style={{ textDecoration: 'none' }} href="https://www.unumkey.fr/" target="_blank" rel="noopener noreferrer"><span className="purple">Unumkey</span></a>, une entreprise de cybersécurité.
              <br />
              Je suis également bénévole au sein de l'association <a style={{ textDecoration: 'none' }} href="https://skytale.fr/" target="_blank" rel="noopener noreferrer"><span className="purple">Skytale</span></a> en tant que <span className="purple">Chef de Projet</span> et <span className="purple">Développeur Java</span>.
              <br />
              <br />
              En dehors de la programmation, voici quelques autres choses que j'aime faire !
            </p>
            <ul>
              <li className="about-activity">
                <ImPointRight/> Découvrir de nouvelles choses
              </li>
              <li className="about-activity">
                <ImPointRight/> Sortir avec des amis
              </li>
              <li className="about-activity">
                <ImPointRight/> Écouter de la musique, particulièrement lors des festivals
              </li>
              <li className="about-activity">
                <ImPointRight/> Jouer aux jeux vidéo
              </li>
            </ul>
          </blockquote>
        </Card.Body>
      </Card>
  );
}

export default AboutCard;
