// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-card-view {
    background: linear-gradient(145deg, #2a0845 0%, #6441A5 100%);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(100, 65, 165, 0.2);
    transition: all 0.5s ease;
    height: 100%;
    overflow: hidden;
}

.project-card-view:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(100, 65, 165, 0.3);
}

.project-card-view .card-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.project-card-view .card-text {
    font-family: 'Roboto', sans-serif;
}

.tech-badge {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 0.9em;
}

.btn-primary {
    background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
    border: none !important;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(142, 45, 226, 0.4);
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Projects/ProjectCards.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,mBAAmB;IACnB,8CAA8C;IAC9C,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,8CAA8C;AAClD;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,0CAA0C;IAC1C,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,4DAA4D;IAC5D,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;IAC3B,8CAA8C;AAClD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".project-card-view {\n    background: linear-gradient(145deg, #2a0845 0%, #6441A5 100%);\n    border-radius: 15px;\n    box-shadow: 0 4px 20px rgba(100, 65, 165, 0.2);\n    transition: all 0.5s ease;\n    height: 100%;\n    overflow: hidden;\n}\n\n.project-card-view:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 30px rgba(100, 65, 165, 0.3);\n}\n\n.project-card-view .card-title {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 600;\n}\n\n.project-card-view .card-text {\n    font-family: 'Roboto', sans-serif;\n}\n\n.tech-badge {\n    background-color: rgba(255, 255, 255, 0.1);\n    border-radius: 20px;\n    padding: 5px 10px;\n    margin-right: 5px;\n    margin-bottom: 5px;\n    display: inline-flex;\n    align-items: center;\n    font-size: 0.9em;\n}\n\n.btn-primary {\n    background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);\n    border: none !important;\n    border-radius: 25px;\n    padding: 10px 20px;\n    font-weight: bold;\n    transition: all 0.3s ease;\n}\n\n.btn-primary:hover {\n    transform: translateY(-2px);\n    box-shadow: 0 5px 15px rgba(142, 45, 226, 0.4);\n}\n\n.button-group {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
