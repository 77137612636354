import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {AiFillGithub} from "react-icons/ai";
import { FaLinkedinIn} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright"></Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} <span className="purple">Corentin Dupont</span></h3>
          <p style={{ color: 'white', fontSize: '13px' }}>Conçu et développé par <a style={{ textDecoration: 'none' }} href="https://github.com/soumyajit4419/Portfolio" target="_blank" rel="noopener noreferrer"><span className="purple">Soumyajit Behera</span></a></p>
        </Col>
        <Col md="4" className="footer-body" style={{ marginTop: '7px' }} >
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                  href="https://x.com/_C0rin_"
                  style={{color: "white"}}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <FaSquareXTwitter/>
              </a>
            </li>
            <li className="social-icons">
              <a
                  href="https://github.com/Corin-alt"
                  style={{color: "white"}}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <AiFillGithub/>
              </a>
            </li>
            <li className="social-icons">
              <a
                  href="https://www.linkedin.com/in/corentindpt51/"
                  style={{color: "white"}}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <FaLinkedinIn/>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
