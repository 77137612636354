import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import noImg from "../../Assets/no-img.png";

function Projects() {
  return (
      <Container fluid className="project-section">
        <Particle />
        <Container>
          <h1 className="project-heading">
            <strong className="purple">Projets </strong> personnel
          </h1>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Flaco"
                  description="Une petite application client/serveur dans GO pour découvrir GRPC."
                  ghLink="https://github.com/Corin-alt/Flaco"
                  technologies={["Go", "MongoDB", "Docker"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Hangman"
                  description="Le pendu est un jeu mobile consistant à trouver un mot en devinant les lettres qui le composent."
                  ghLink="https://github.com/Corin-alt/Hangman"
                  technologies={["Java"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Javatheque"
                  description="Rest API pour la gestion de la vidéothèque."
                  ghLink="https://github.com/Corin-alt/Javatheque"
                  technologies={["Java", "MongoDB", "Docker"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Loki"
                  description="Loki est une application web qui permet de gérer l'assiduité des étudiants à leurs cours."
                  ghLink="https://github.com/Corin-alt/Loki"
                  technologies={["PHP", "Eloquent", "Laravel"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Neon"
                  description="Un petit projet pour reproduire le jeu gratuit Flow. Dans ce jeu, vous devez relier des points de couleur sans les croiser."
                  ghLink="https://github.com/Corin-alt/Neon"
                  technologies={["Python"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="PKI"
                  description="Création d'une infrastructure à clé publique (PKI) avec différents scénarios. Elle permet de gérer les clés privées, les clés publiques, les signatures, les certificats, etc. "
                  ghLink=""
                  technologies={["Python"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="SCV"
                  description="Premier projet client/serveur. Dans ce projet, nous gérons un constructeur automobile. Lorsqu'un client passe une commande, le serveur vérifie s'il dispose de suffisamment de pièces auprès d'un fournisseur et, si c'est le cas, livre la voiture au client."
                  ghLink="https://github.com/Corin-alt/SCV"
                  technologies={["PHP", "Java"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Ticketing"
                  description="Un petit projet IoT qui vous permet d'acheter un billet, de le scanner comme un Qrcode et d'ouvrir les portes si le billet est valide ou non. Une API a été développée."
                  ghLink="https://github.com/Corin-alt/Ticketing"
                  technologies={["Python", "Flask", "MongoDB", "Docker"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={false}
                  hasWIPButton={false}
                  infoLink=""
                  title="Unknown"
                  description="Unknown est un bot Discord. Il dispose de différentes fonctionnalités comme des commandes, des événements, des jeux, etc."
                  ghLink="https://github.com/Corin-alt/Unknown"
                  technologies={["JavaScript"]}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <h1 className="project-heading">
            Avec <strong className="purple">Skytale </strong>
          </h1>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={false}
                  hasWIPButton={true}
                  infoLink=""
                  title="Block Animation"
                  description="Block Animation est un plugin qui vous permet de créer une variété d'animations pour les blocs dans Minecraft. Il peut être utilisé avec ses propres commandes ou être inclus directement dans d'autres codes."
                  ghLink=""
                  technologies={["Java", "Spigot"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={true}
                  hasWIPButton={false}
                  infoLink="https://www.pixelcrew.fr/children-of-steel"
                  title="Children of Steel"
                  description="Children Of Steel est un événement caritatif dont le but est de récolter des dons via les streams des différents participants, au profit de l'association Un Cadeau Pour La Vie. Il est basé sur le mode de jeu Balls of Steel, qui a été revisité pour l'occasion."
                  ghLink=""
                  technologies={["Java", "Spigot"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={true}
                  hasWIPButton={false}
                  infoLink="https://www.minecraft-france.fr/evenement-la-cite-des-cieux/"
                  title="Cité des Cieux"
                  description="La Cité des Cieux est un événement sur Minecraft, où 200 joueurs en équipes de 4 doivent collécter le plus d'éemraudes sur 1 semaine."
                  ghLink=""
                  technologies={["Java", "MySQL", "Spigot"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={true}
                  hasWIPButton={false}
                  infoLink="https://www.minecraft-france.fr/evenement-city-of-nations/"
                  title="City of Nations"
                  description="City of Nations est un événement Minecraft qui oppose cinq équipes de streamers. L'objectif est de collecter le plus d'émeraudes possible, dans un format similaire à celui de la Cité des Cieux, précédemment organisé par Skytale."
                  ghLink=""
                  technologies={["Java", "MySQL", "Spigot"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={true}
                  hasInfo={true}
                  hasWIPButton={false}
                  infoLink="https://skyjam.corentin-dupont.fr/pitfall_miner/"
                  title="Pitfall Miner"
                  description="Jeu d'arcade en pixels créé lors de l'événement GameJam de Skytale. L'objectif du jeu est d'obtenir le meilleur score possible en améliorant votre pioche et en évitant les obstacles mortels. "
                  ghLink="https://github.com/AsmaChloe/skyjam"
                  technologies={["Python", "Pygame"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={false}
                  hasWIPButton={true}
                  infoLink=""
                  title="Skytale Map Editor"
                  description="Skytale Map Editor est un projet ambitieux qui vise à devenir un éditeur de jeu Minecraft open-source à la portée de tous. Un écosystème complet de bibliothèques logicielles qui facilite le développement, ses outils permettent à chacun de créer des cinématiques, d'animer des décors, de modifier la physique des objets, d'implémenter des sons, des dialogues, de la musique et bien d'autres choses encore."
                  ghLink=""
                  technologies={["Java", "Spigot"]}
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                  // imgPath={noImg}
                  hasAccessOfCode={false}
                  hasInfo={true}
                  hasWIPButton={true}
                  infoLink="https://skytale.fr/the-last-artifact/"
                  title="The Last Artefact"
                  description="Il s'agit d'un jeu dont le gameplay se déroule sur des cartes entièrement conçues par notre équipe, avec une foule de personnages originaux.  A cela s'ajoutent des centaines de modèles 3D, leur physique et des personnages 3D avec leurs propres animations, caractères, compétences et doublages. La composition musicale et le design sonore offrent une véritable aventure auditive conçue pour une immersion totale. Jouable à partir de 14 ans, le jeu s'appuie fortement sur ses puzzles à systèmes laser : addition, soustraction, déviation, etc."
                  ghLink=""
                  technologies={["Java", "Spigot"]}
              />
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Projects;