import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
          strings: [
              "Futur Ingénieur Logiciel",
              "Apprenti chez Unumkey",
              "Bénévole chez Skytale",
          ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
