import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub, BsGitlab } from "react-icons/bs";
import { MdBuildCircle } from "react-icons/md";
import "./ProjectCards.css"; // Make sure you have a CSS file for specific styles

function ProjectCards(props) {
    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>
                    <span className="purple" style={{fontSize: '26px'}}>{props.title}</span>
                </Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    {props.description}
                </Card.Text>
                {props.technologies && (
                    <div className="mt-2 mb-3">
                        {props.technologies.map((tech, index) => (
                            <span key={index} className="tech-badge">
                                {tech}
                            </span>
                        ))}
                    </div>
                )}
                <div className="button-group">
                    {props.hasAccessOfCode && (
                        <Button variant="primary" href={props.ghLink} target="_blank">
                            <BsGitlab /> &nbsp; Code
                        </Button>
                    )}
                    {props.hasWIPButton && (
                        <Button variant="primary" disabled>
                            WIP
                        </Button>
                    )}
                    {props.hasInfo && (
                        <Button
                            variant="primary"
                            href={props.infoLink}
                            target="_blank"
                        >
                            <CgWebsite /> &nbsp; Info
                        </Button>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProjectCards;